export type Menu = {
  key: string
  label: string
  href: string
  isLegacy?: boolean
  disabled?: boolean
  children?: Menu[]
}

export const menu: Menu[] = [
  {
    key: '.home',
    label: 'Menu.MENU_HOME',
    href: '/'
  },
  {
    key: '.destination',
    label: 'Menu.MENU_LOCATION',
    href: '/destination',
    isLegacy: true,
    children: [
      {
        key: '.chiang-mai',
        label: 'Menu.CITY_CNX',
        href: '/destination/chiangmai',
        isLegacy: true,
        children: [
          {
            key: '.nimman',
            label: 'nimman',
            href: '/destination/chiangmai/Nimman',
            isLegacy: true
          },
          {
            key: '.lanna',
            label: 'lanna',
            href: '/destination/bangkok/Lanna_Wat_Prasing',
            isLegacy: true
          }
        ]
      },
      {
        key: '.bangkok',
        label: 'Menu.CITY_BKK',
        href: '/destination/bangkok',
        isLegacy: true,
        children: [
          {
            key: '.gaaya',
            label: 'GAAYA by Oasis Spa',
            href: '/destination/bangkok/gaaya39',
            isLegacy: true
          },
          {
            key: '.urban',
            label: 'Urban Oasis Spa',
            href: '/destination/bangkok/Urban',
            isLegacy: true
          },
          {
            key: '.sukhumvit31',
            label: 'Sukhumvit 31',
            href: '/destination/bangkok/Sukhumvit31',
            isLegacy: true
          }
        ]
      },
      {
        key: '.pattaya',
        label: 'Menu.CITY_PTY',
        href: '/destination/pattaya',
        isLegacy: true,
        children: [
          {
            key: '.jomtien',
            label: 'Pattaya Jomtien',
            href: '/destination/pattaya/Pattaya_Jomtien',
            isLegacy: true
          }
        ]
      },
      {
        key: '.phuket',
        label: 'Menu.CITY_PKT',
        href: '/destination/phuket',
        isLegacy: true,
        children: [
          {
            key: '.tropical-retreat',
            label: 'Tropical Retreat Spa',
            href: '/destination/phuket/Tropical_Retreat_Spa',
            isLegacy: true
          },
          {
            key: '.tropical-cove',
            label: 'Turquoise Cove Spa',
            href: '/destination/phuket/Turquoise_Cove_Spa',
            isLegacy: true
          },
          {
            key: '.sky-breeze',
            label: 'Sky Breeze Spa',
            href: '/destination/phuket/Sky_Breeze_Spa',
            isLegacy: true
          }
        ]
      }
    ]
  },
  {
    key: '.promotion',
    label: 'Menu.MENU_PROMOTION',
    href: '/promotions',
    isLegacy: true
  },
  {
    key: '.package',
    label: 'Menu.MENU_SPA_PACKAGES',
    href: '/package',
    isLegacy: true,
    children: [
      {
        key: '.2hours',
        label: 'Menu.SPA_PACKAGES_MENU_CAP',
        href: '/package/2hour_h',
        isLegacy: true
      },
      {
        key: '.2-5hours',
        label: 'Menu.SPA_PACKAGES_MENU1_CAP',
        href: '/package/2hhour_h',
        isLegacy: true
      },
      {
        key: '.3hours',
        label: 'Menu.SPA_PACKAGES_MENU3_CAP',
        href: '/package/3hour_h',
        isLegacy: true
      }
    ]
  },
  {
    key: '.treatment',
    label: 'Menu.MENU_SPA_TREATMENT',
    href: '/treatment',
    isLegacy: true,
    children: [
      {
        key: '.bodyscrubs',
        label: 'Menu.BODY_SCRUBS_MENU',
        href: '/treatment/bodyscrubs',
        isLegacy: true
      },
      {
        key: '.bodywraps',
        label: 'Menu.BODY_WRAPS_MENU',
        href: '/treatment/bodywraps',
        isLegacy: true
      },
      {
        key: '.facial',
        label: 'Menu.FACIAL_TREATMENTS_MENU',
        href: '/treatment/facial',
        isLegacy: true
      },
      {
        key: '.hydrotherapy',
        label: 'Menu.HYDROTHERAPY_MENU',
        href: '/treatment/hydrotherapy',
        isLegacy: true
      }
    ]
  },
  {
    key: '.massage',
    label: 'Menu.MASSAGE_MENU',
    href: '/massage',
    isLegacy: true,
    children: [
      {
        key: '.signature-treatments',
        label: 'Menu.SIGNATUE_MASSAGE_MENU',
        href: '/massage/signature',
        isLegacy: true
      },
      {
        key: '.traditional-massage',
        label: 'Menu.TRADITIONAL_MASSAGE_MENU',
        href: '/massage/traditional',
        isLegacy: true
      },
      {
        key: '.kids',
        label: 'Menu.KIDS_TREATMENT_MENU',
        href: '/massage/kids',
        isLegacy: true
      }
    ]
  },

  {
    key: '.gift-certificate',
    label: 'Menu.MENU_GIFT',
    href: '/perfectgift/giftcertificate',
    isLegacy: true,
    children: [
      {
        key: '.buy-voucher',
        label: 'Menu.MENU_BUYGIFT',
        href: '/perfectgift/giftcertificate',
        isLegacy: true
      },
      {
        key: '.redeem-voucher',
        label: 'Menu.MENU_REDEEMGIFT',
        href: '/onlinegift/redeem',
        isLegacy: true
      }
    ]
  },
  {
    key: '.membership',
    label: 'Menu.MENU_MEMBERSHIP',
    href: '/perfectgift/membership',
    isLegacy: true
  }
]
