'use client'

import cls from 'classnames'
import { isAfter } from 'date-fns'
import { useCallback, useEffect, useRef, useState } from 'react'
import { motion } from 'framer-motion'

import type { Toast } from './toast.type'

type Props = {
  record: Toast
  onRemove?: (toast: Toast) => void
}

export function ToastItem({ record, ...rest }: Props) {
  // __STATE's
  const nodeRef = useRef<HTMLDivElement>(null)
  const [visible, setVisible] = useState<boolean>(false)

  // __FUNCTION's
  const handleClose = useCallback(() => {
    if (rest?.onRemove) {
      rest.onRemove(record)
    }
  }, [record, rest])

  // __EFFECT's
  useEffect(() => {
    setVisible(record.visible)

    let intervalId: NodeJS.Timeout
    if (record.duration) {
      const duration = record.duration
      intervalId = setInterval(() => {
        if (isAfter(Date.now(), duration)) {
          clearInterval(intervalId)
          setVisible(false)
        }
      }, 250)
    }

    return () => {
      clearInterval(intervalId)
    }
  }, [record])

  // __RENDER
  return (
    <motion.div
      ref={nodeRef}
      className={cls('ui--toast-item', { 'ui--toast-visible': visible })}
      initial={{ opacity: 0 }}
      animate={{ opacity: visible ? 1 : 0 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      onClick={handleClose}
    >
      <p className="px-6 py-2">{record.children}</p>
    </motion.div>
  )
}
