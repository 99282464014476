import axios from 'axios'
import { IntlErrorCode } from 'next-intl'
import { createNavigation } from 'next-intl/navigation'
import { defineRouting } from 'next-intl/routing'
import { getRequestConfig } from 'next-intl/server'
import { z } from 'zod'

import { configs } from '@/constants'

export const routing = defineRouting({
  locales: ['en-US', 'th-TH', 'ko-KR', 'ja-JP', 'zh-CN', 'zh-TW'],
  defaultLocale: 'en-US'
})

export const locales = [
  { locale: routing.locales[0], short: 'en', label: 'English (US)' },
  { locale: routing.locales[1], short: 'th', label: 'ภาษาไทย' },
  { locale: routing.locales[2], short: 'ko', label: '한국어' },
  { locale: routing.locales[3], short: 'jp', label: '日本語' },
  { locale: routing.locales[4], short: 'ch', label: '中文（简）' },
  { locale: routing.locales[5], short: 'tw', label: '中文（繁）' }
]

export const { Link, redirect, usePathname, useRouter } = createNavigation(routing)

export const localeQueryValidator = z.object({
  locale: z.enum([...routing.locales])
})

export default getRequestConfig(async ({ requestLocale }) => {
  // Read from potential `[locale]` segment.
  let locale = await requestLocale

  // Ensure that the incoming locale is valid
  if (!locale || !routing.locales.includes(locale as any)) {
    locale = routing.defaultLocale
  }

  const { data } = await axios.get(`/api-v2/locales/messages`, {
    baseURL: configs.APP_BASE_URL,
    params: { locale }
  })

  return {
    locale,
    messages: data[locale]
  }
})
