import { type Menu, menu } from '@/constants/menu'
import { createAction, createReducer } from '@reduxjs/toolkit'

/**
 * STATE
 */
export type CommonState = {
  menu: Menu[]
}

export const initialState: CommonState = {
  menu
}

/**
 * ACTION's
 */
export enum ActionTypes {
  SET_MENU = 'SET_COMMON_MENU'
}

export class commonAct {
  static setMenu = createAction<Menu[], ActionTypes>(ActionTypes.SET_MENU)
}

/**
 * REDUCER's
 */
export default createReducer(initialState, (builder) => {
  return builder.addCase(commonAct.setMenu, (state, { payload }) => {
    state.menu = payload
  })
})
