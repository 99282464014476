import axios, { AxiosHeaders, AxiosInstance } from 'axios'

import { configs, headers } from '@/constants'
import { cookie } from '@/utils/storage'

/**
 * Create axios instance.
 */
const Axios: AxiosInstance = axios.create({
  baseURL: `${configs.isBrowser ? configs.APP_BASE_URL : configs.API_GATEWAY}`,
  headers: {
    ...(headers as AxiosHeaders),
    'Access-Control-Allow-Origin': configs.isBrowser ? configs.APP_BASE_URL : '*'
  }
})

/**
 * Axios also provides a request interceptor, allows changes to the request data before it is sent to the server
 * This is only applicable for request methods 'POST', 'PATCH' and 'DELETE'
 * The last function in the array must return a string or an instance of Buffer, ArrayBuffer,
 * FormData or Stream
 * You may modify the headers object.
 */
Axios.interceptors.request.use((reqConfig) => {
  if (configs.isBrowser) {
    const accessToken = cookie.get(configs.APP_AUTH_ACCESS)
    if (accessToken) {
      reqConfig.headers.set('Authorization', `Bearer ${accessToken}`)
    }

    const language = cookie.get(configs.APP_LANG)
    if (language) {
      reqConfig.headers.set(configs.CONTENT_LANG, language)
    }
  } else {
    reqConfig.headers.set('secretKey', configs.API_SECRET_KEY)
  }

  return reqConfig
})

export default Axios
export const { CancelToken } = axios
