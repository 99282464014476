'use client'

import cls from 'classnames'
import { useEffect, useRef, useState } from 'react'
//import { CSSTransition } from 'react-transition-group'
import { motion } from 'framer-motion'

import type { Dialog } from './dialog.type'

type Props = {
  data: Extract<Dialog, { type: 'modal' }>
  onRemove?: () => void
}

export function ModalComponent({ data, ...rest }: Props) {
  // __STATE's
  const nodeRef = useRef<HTMLDivElement>(null)
  const timeoutId = useRef<NodeJS.Timeout>()

  const [state, setState] = useState<boolean>(false)

  // __EFFECT's
  useEffect(() => {
    timeoutId.current = setTimeout(() => setState(data.visible), 32)
    return () => clearTimeout(timeoutId.current)
  }, [timeoutId, data.visible])

  // __RENDER
  return (
    <motion.div
      ref={nodeRef}
      className={cls('ui--dialogs-context as-modal', data?.style?.align || 'center')}
      data-vid={data.vid}
      initial={{ opacity: 0 }}
      animate={{ opacity: state ? 1 : 0 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.256 }}
      onAnimationComplete={rest?.onRemove}
    >
      {typeof data.children === 'string' ? (
        <div className='_dangerously' dangerouslySetInnerHTML={{ __html: data.children }} />
      ) : (
        data.children
      )}
    </motion.div>
  )
}
