export const APP_MODE = process.env.APP_MODE
export const APP_LOGS = process.env.APP_LOGS
export const APP_NAME = process.env.NEXT_PUBLIC_APP_NAME || 'project_oasisspa'
export const APP_BASE_URL = process.env.NEXT_PUBLIC_BASE_URL || 'http://localhost:8080'
export const API_GATEWAY = process.env.NEXT_PUBLIC_API_GATEWAY || 'http://localhost:3030'
export const API_SECRET_KEY = process.env.NEXT_PUBLIC_API_SECRET_KEY || 'AP1-S3C23T-K3Y'
export const WS_GATEWAY = process.env.WS_GATEWAY || 'ws://127.0.0.1:5050'

export const isBrowser = typeof window !== 'undefined'
export const isProduction = APP_MODE === 'production'
export const isDevelop = APP_MODE === 'development'
export const isLocal = APP_MODE === 'local'

// Log
export const LOG_LEVEL = process.env.NEXT_PUBLIC_LOG_LEVEL || 'info'
export const LOG_FILE_PATH = process.env.NEXT_PUBLIC_LOG_FILE_PATH
export const ENABLE_FILE_LOGS = process.env.NEXT_PUBLIC_ENABLE_FILE_LOGS === 'true' ? true : false
export const ENABLE_CONSOLE_LOGS = process.env.NEXT_PUBLIC_ENABLE_CONSOLE_LOGS === 'true' ? true : false

// STORAGE KEY-NAME
export const APP_LANG = `__APP.Language${isDevelop ? `-${APP_NAME}` : ''}`
export const APP_THEME = `__APP.Theme${isDevelop ? `-${APP_NAME}` : ''}`
export const APP_AUTH_ACCESS = `__APP.AccessToken${isDevelop ? `-${APP_NAME}` : ''}`
export const APP_AUTH_REFRESH = `__APP.RefreshKey${isDevelop ? `-${APP_NAME}` : ''}`
export const APP_USER_INFO = `__APP.UserInfo${isDevelop ? `-${APP_NAME}` : ''}`

// REQUEST HEADERS
export const AUTHORIZATION = 'Authorization'
export const ACCEPT_RANGES = 'Accept-Ranges'
export const ACCEPT_LANG = 'Accept-Language'
export const CONTENT_RANGE = 'Content-Range'
export const CONTENT_TYPE = 'Content-Type'
export const CONTENT_LENGTH = 'Content-Length'
export const CONTENT_LANG = 'Content-Language'
